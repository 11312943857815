import { RasaContext } from 'context'
import * as React from 'react'
import * as Reactstrap from 'reactstrap'
import ReactMarkdown from 'react-markdown'

import { HeaderComponent } from 'components/header/component'
import { Loading } from 'components/loading'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'

import './_styles.scss'

interface AccountDiscovery {
  image: string
  colors: {
    primary: string,
    secondary: string,
  },
  style: string
  summary: string
  users: string[]
}

interface State {
  data?: AccountDiscovery,
  raw?: {
    account: any,
    puppeteer: any,
    jina: any,
  }
  id: string,
  accountId: string,
  identifier: string,
  isLoading: boolean,
  open: string,
  website: string,
}

const EMPTY_STATE: State = {
  data: null,
  raw: null,
  accountId: '',
  id: '',
  identifier: '',
  isLoading: false,
  open: '',
  website: '',
}

interface ColorProps {
  title: string
  color: string
}

class Color extends React.Component<ColorProps> {

  public render () {
    return  <div>
      <span>{this.props.title}: {this.props.color}</span>
      <div style={{
          marginTop: '20px',
          width: '100px',
          height: '100px',
          backgroundColor: this.props.color,
          border: '1px solid #ccc'
      }} />
    </div>
  }

}

interface ExpanderProps {
  name: string,
  open: string,
  title: string,
  toggle: (name: string) => void,
}


class Expander extends React.Component<ExpanderProps> {
  public render() {
    return <Reactstrap.Card>
      <Reactstrap.CardHeader>
        {this.props.title}
      </Reactstrap.CardHeader>
      <Reactstrap.Button color="primary" onClick={() => this.props.toggle(this.props.name)} style={{ marginBottom: '1rem' }}>
        { this.isOpen() ? 'Collapse' : 'Expand' }
      </Reactstrap.Button>
      <Reactstrap.Collapse className="card" isOpen={this.isOpen()}
                     style={{ display: this.isOpen() ? 'block' : 'none' }}>
        <Reactstrap.CardBody>
          {this.props.children}
        </Reactstrap.CardBody>
      </Reactstrap.Collapse>
    </Reactstrap.Card>

  }
  protected isOpen = () => {
    return this.props.open === this.props.name
  }
}

export class AccountDiscoveryComponent extends React.Component<any, State> {
  public static contextType = RasaContext;

  constructor(props) {
    super(props)
    this.state = EMPTY_STATE
  }

  public render(): React.ReactNode {
    return <div className="account-discovery">
      <HeaderComponent
        title={'Account'}
        subTitle={'Discovery'}
        description={['Discover information about your website.']}
      />
      <Reactstrap.Row className="header">
        <Reactstrap.Col className="url">
          <Reactstrap.Input value={this.state.website} onChange={(e) => this.setState({website: e.target.value})}/>
        </Reactstrap.Col>
        <Reactstrap.Col className="actions">
          <Reactstrap.Button disabled={this.state.isLoading}
                           onClick={this.discover}>Discover</Reactstrap.Button>
          <Reactstrap.Button disabled={!this.state.data}
                             onClick={this.save}>Save</Reactstrap.Button>
        </Reactstrap.Col>

      </Reactstrap.Row>
      <Reactstrap.Row className="spacer">
        <div></div>
      </Reactstrap.Row>
      { this.state.isLoading && <Loading size="64" /> }
      { this.state.data && this.state.id &&
      <Reactstrap.Row className="body">
        <Reactstrap.Row className="details">
          <Reactstrap.Col className="header-image half">
            <span>Banner</span>
            <div>
              <img src={this.state.data.image} />
            </div>
          </Reactstrap.Col>
          <Reactstrap.Col className="color quarter">
            <Color title="Primary" color={this.state.data.colors.primary} />
          </Reactstrap.Col>
          <Reactstrap.Col className="color quarter">
            <Color title="Secondary" color={this.state.data.colors.secondary} />
          </Reactstrap.Col>
        </Reactstrap.Row>
        <Reactstrap.Row className="details">
          <h4>Summary</h4>
          <span>{this.state.data.summary}</span>
        </Reactstrap.Row>
        <Reactstrap.Row className="details">
          <h4>Style</h4>
          <span>{this.state.data.style}</span>
        </Reactstrap.Row>
      { this.state.data.users &&
        <Reactstrap.Row className="details">
          <h4>Typical Users</h4>
          <ul>
            {this.state.data.users.map((user, index) => <li key={index}>{user}</li>)}
          </ul>
        </Reactstrap.Row>
      }
      </Reactstrap.Row>}
      { this.state.raw &&
      <Reactstrap.Row className="body cards">
        <Reactstrap.Row className="details">
          { this.jinaCard() }
        </Reactstrap.Row>
        <Reactstrap.Row className="details">
          { this.pupeteerImageCard() }
        </Reactstrap.Row>
        <Reactstrap.Row className="details">
          { this.accountCard() }
        </Reactstrap.Row>
        <Reactstrap.Row className="details">
          { this.jinaRawCard() }
        </Reactstrap.Row>
        <Reactstrap.Row className="details">
          { this.jinaJSONCard() }
        </Reactstrap.Row>
      </Reactstrap.Row>
    }
    </div>
  }

  public componentDidMount = () => {
    this.context.user.init().then(({person, activeCommunity}) => {
      const accountData = activeCommunity.billingInfo.productSubscription
      this.setState({
        accountId: accountData.account_id,
        identifier: activeCommunity.communityId,
        website: accountData.account_website,
      }, () => {
        this.load(activeCommunity.communityId, accountData.account_id)
      })
    })
  }

  private pupeteerImageCard = () => {
    const { image } = this.state.raw.puppeteer.raw || {}
    const { primary, secondary } = this.state.raw.puppeteer.analyzed.json || {}
    return image && <Expander title="Screenshot" name="screenshot" open={this.state.open} toggle={this.toggleCard}>
      <Reactstrap.Row>
        { primary && <Reactstrap.Col><Color title="Primary" color={primary} /></Reactstrap.Col>}
        { secondary && <Reactstrap.Col><Color title="Secondary" color={secondary} /></Reactstrap.Col>}
        <img src={image} />
      </Reactstrap.Row>
    </Expander>
  }

  private accountCard = () => {
    return this.state.raw.account && <Expander title="Account Information" name="account" open={this.state.open} toggle={this.toggleCard}>
      <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
        {JSON.stringify(this.state.raw.account, null, 2)}
      </pre>
    </Expander>
  }

  private jinaCard = () => {
    const { summary, users, style, elements } = this.state.raw.jina.analyzed.json || {}
    return summary && <Expander title="JINA Data" name="jina" open={this.state.open} toggle={this.toggleCard}>
      { summary && <div>
        <h4>Summary:</h4>
        <span>{summary}</span>
      </div>}
      { style && <div>
        <h4>Style:</h4>
        <span>{style}</span>
      </div>}
      { users && <div>
          <h4>Users:</h4>
          <ul>
            {users.map((user, index) => <li key={index}>{user}</li>)}
          </ul>
        </div>}
      { elements && <div>
        <h4>Elements:</h4>
          { elements.primary && <div>
            <Color title="Primary" color={elements.primary} />
          </div>}
          { elements.secondary && <div>
            <Color title="Secondary" color={elements.secondary} />
          </div>}
          { elements.image && <div>
            <h4>Image</h4>
            <img src={elements.image} />
          </div>}
        </div>}
    </Expander>
  }

  private jinaRawCard = () => {
    const { text } = this.state.raw.jina.raw || {}
    return text && <Expander title="Raw JINA Markdown" name="jina_raw" open={this.state.open} toggle={this.toggleCard}>
      <ReactMarkdown>{text}</ReactMarkdown>
    </Expander>
  }

  private jinaJSONCard = () => {
    const { json } = this.state.raw.jina.analyzed|| {}
    return json && <Expander title="JINA Breakdown" name="jina_json" open={this.state.open} toggle={this.toggleCard}>
      <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
        {JSON.stringify(json, null, 2)}
      </pre>
    </Expander>
  }

  private load = (identifier: string, accountId: string) => {
    this.setState({
      isLoading: true
    }, () => {
      this.context.entityMetadata.getEntityObject('accountDiscovery')
        .then((entityObject) => {
          entityObject.load(identifier, accountId)
            .then(() => {
              this.setState({
                isLoading: false,
                id: entityObject.data.id,
                data: {
                  ...(entityObject.data || {}),
                  users: (entityObject.data.content || {}).users,
                  style: (entityObject.data.content || {}).style,
                }
              })
            })
        })
    })
  }

  private save = () => {
    this.setState({
      isLoading: true
    }, () => {
      this.context.entityMetadata.getEntityObject('accountDiscovery')
        .then((entity) =>  {
          entity.setRecordId(this.state.identifier, this.state.id)
          entity.data.accountId = this.state.accountId
          entity.data.summary = this.state.data.summary
          entity.data.colors = this.state.data.colors
          entity.data.content = {
            style: this.state.data.style,
            users: this.state.data.users
          }
          entity.data.image = this.state.data.image
          entity.save().then(() => this.setState({isLoading: false}))
        })
    })
  }

  private discover = () => {
    this.setState({
      isLoading: true,
      data: null,
      raw: null,
    }, () => {
      AjaxWrapper
        .ajax(`${AjaxWrapper.getServerUrl()}/rex/${this.state.identifier}/discover`, HttpMethod.POST, {url: this.state.website})
        .then((response) => {
          this.setState({
            data: response.results.data,
            raw: response.results.raw,
            isLoading: false
          })
        })
        .catch((error) => {
          this.setState({ isLoading: false })
        })

      })
  }

  private toggleCard = (card) => {
    if ( card === this.state.open ) {
      this.setState({open: null})
    } else {
      this.setState({open: card})
    }
  }

}